import Img from 'gatsby-image';
import PropTypes from 'prop-types';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const Image = ({ imgData, name, ...rest }) => (
  <Img
    fluid={imgData[name].childImageSharp.fluid}
    loading="eager"
    placeholderStyle={{ visibility: 'hidden' }}
    {...rest}
  />
);

Image.propTypes = {
  imgData: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
};

export default Image;
