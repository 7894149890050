import styled from '@emotion/styled';
import { media } from '../Theme/Media';
import { Body1, Body2 } from '../Theme/Typography';

export const P1 = styled.p`
  ${Body1};

  ${media.mobileWide`
    ${Body2}
  `}
`;
