import styled from '@emotion/styled';
import { media } from '../Theme/Media';
import { Heading3, Heading6 } from '../Theme/Typography';

export const H3 = styled.h3`
  ${Heading3};

  ${media.mobileWide`
    ${Heading6};
  `}
`;
